.slide#my_expertise_slide{
    height: auto;
}

.slide_container#my_expertise_slide_container{
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* height: 100%; */
    /* border: 2px solid purple; */
    padding: 50px;
    gap: 18px;
    height: auto;
}

#my_expertise_slide_container .component_container{
    display: flex;
    flex-direction: row;
    /* border: 2px solid blue; */
    justify-content: center;
    gap: 18px;
}

#my_expertise_slide_container .slide_content#slide-title{
    font-size: xx-large;
}