.slide#my_projects_slide {
    height: auto;
}

.slide_container#my_projects_slide_container {
    display: flex;
    flex-direction: column;
    padding: 50px;
    gap: 18px;
    height: auto;
    justify-content: center;
}

#my_projects_slide_container .component_container {
    display: flex;
    flex-direction: row;
    gap: 3px;
    padding: 0 3px;
    justify-content: space-between; /* Distribute space between items */
}

#my_projects_slide_container .component_container:first-child {
    padding-top: 3px;
}

#my_projects_slide_container .component_container:last-child {
    padding-bottom: 3px;
}

#my_projects_slide_container .slide_content#slide-title{
    font-size: xx-large;
}