.project-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f0f0;
}

.project-dashboard h2 {
    margin-bottom: 20px;
}

.project-dashboard form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin-bottom: 40px;
}

.project-dashboard form .MuiTextField-root {
    margin-bottom: 20px;
    width: 100%;
}

.project-dashboard form input[type="file"] {
    margin-bottom: 20px;
}

.project-dashboard form .MuiButton-root {
    margin-top: 20px;
}
