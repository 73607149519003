.slide#socials_slide{
    width: 100%;
    height: auto;
}

.slide_container#socials_slide_container{
    display: flex;
    flex-direction: row;
    /* width: 100%; */
    height: 100%;
    padding: 50px;
    gap: 70px;
    justify-content: center;
    /* height: auto; */
}

.slide_content#intro-phrase{
    font-size:x-large;
}

.slide_content#name{
    font-size: xx-large;
}

.slide_content#introduction{
    font-size: large;
}