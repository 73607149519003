.slide#intro_slide{
    width: 100%;
    height: auto;
}

.slide_container#intro_slide_container{
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    height: 100%;
    /* border: 2px solid green; */
    padding: 50px;
    gap: 20px;
    /* height: auto; */
}

.slide_content#intro-phrase{
    font-size:x-large;
}

.slide_content#name{
    font-size: xx-large;
}

.slide_content#introduction{
    font-size: large;
}