/* General Slide Styling */
:root {
    --color-palette-1: #463F3A;
    --color-palette-2: #8A817C;
    --color-palette-3: #BCB8B1;
    --color-palette-4: #F4F3EE;
    --base-color: #000;

    --transition-sparkle-color-1: var(--color-palette-2);
    --transition-sparkle-color-2: var(--base-color);

    --radial-gradient-background: radial-gradient(circle, var(--color-palette-4) 0%, var(--color-palette-3) 15%, var(--color-palette-2) 31%, var(--color-palette-1) 48%);
    --skill-card-glare-color: var(--color-palette-3);

    --timeline-line-color: var(--color-palette-3);
    --timeline-element-icon-background-color: var(--color-palette-1);
    --timeline-element-icon-color: var(--color-palette-4);
    --timeline-element-icon-box-shadow: var(--color-palette-2);

    --timeline-element-content-background-color: var(--color-palette-1);
    --timeline-element-content-font-color: var(--color-palette-4);
    --timeline-element-content-arrow-color: var(--color-palette-3);
    --timeline-element-content-box-shadow: var(--color-palette-2);
}

/* Intro Slide Theme*/

.slide #intro_slide_container{
    background-color: var(--color-palette-1);
    color: var(--color-palette-4);
}

/* My Expertise Slide Theme*/

.slide #my_expertise_slide_container{
    /* border: 2px solid red; */
    background-color: black;
}

.skill-card{
    background-color: var(--color-palette-2);
    /* glareColor: "red"; */
}

.tilt-container .card-title{
    color: var(--color-palette-4);
}

#my_expertise_slide_container .slide_content#slide-title{
    color: var(--color-palette-4);
}

/* My Projects Slide Theme */
.slide #my_projects_slide_container{
    background-color: var(--color-palette-1);
}


.features .glow-effect{
    background: var(--radial-gradient-background);
}

.project-card-background{
    background-color: var(--color-palette-2);
}

.project-card-background .card-title-container .card-title{
    color: var(--color-palette-4);
}

.project-card-background .card-info-container .card-info{
    color: var(--color-palette-4);
}

.GitHub-link-container .code-icon{
    color: var(--color-palette-4);
}

#my_projects_slide_container .slide_content#slide-title{
    color: var(--color-palette-4);
}

/* My Experience Slide Theme */
.slide #my_experience_slide_container{
    /* border: 2px solid red; */
    background-color: black;
}

#my_experience_slide_container .slide_content#slide-title{
    color: var(--color-palette-4);
}

/* Socials Slide Theme */
.slide #socials_slide_container{
    background-color: var(--color-palette-1);
}