.features {
    /* width: 100%; */
    height: auto;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    /* border: 2px solid burlywood; */
    /* background-color: black; */
}

.features .children-wrapper{
    display: flex;
    flex-direction: column;
    gap: 3px;
    overflow: hidden;
    justify-content: center;
    background-color: var(--bg-2);
    /* border: 2px solid paleturquoise; */
    z-index: 2;
}

.feature {
    background-color: var(--bg-2);
    text-align: center;
    margin: 10px;
    /* border: 1px solid red; */
    position: relative; /* This ensures z-index is applied correctly */
    z-index: 2; /* Higher than the glow-effect's z-index */
}

.feature .feature-title{
    font-size: 100%;
}

.feature .feature-description{
    font-size: 65%;
}