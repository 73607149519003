.tilt-container {
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  /* border: 2px solid cyan; */
}

.tilt-div {
  /* border: 2px solid green; */
  box-sizing: border-box; /* Include padding and borders in the width calculation */
}

.card-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card-title-container .image-container {
  width: 100px;
  height: auto;
}

.card-title-container .image-container img {
  width: 100%; /* Full width of its container */
  height: auto; /* Maintain aspect ratio */
}

.content-container-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 2px solid red; */
  /* background: var(--accent-glass-1--light); */
  font-weight: bold;
  font-size: 24px;
}

.tilt-container .card-title {
  /* border: 2px solid red; */
  /* color: greenyellow; */
  font-weight: bold;
  font-size: 38px; /* Adjust based on your layout's needs */
  text-align: center;
  /* Ensure text itself is centered */
}