.experience-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f0f0;
}

.experience-dashboard h2 {
    margin-bottom: 20px;
}

.experience-dashboard form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 600px;
    margin-bottom: 40px;
}

.form-class{
    gap: 20px;
}

.experience-dashboard form .MuiTextField-root {
    /* margin-bottom: 20px; */
    width: 100%;
}

.experience-dashboard form .MuiButton-root {
    margin-top: 20px;
}