.slide#my_experience_slide{
    height: auto;
}
.slide_container#my_experience_slide_container{
    display: flex;
    flex-direction: column;
    height: 100%;
    /* border: 2px solid orange; */
    padding: 50px;
    gap: 18px;
}

#my_experience_slide_container .component_container{
    /* border: 2px solid yellow; */
}

#my_experience_slide_container .slide_content#slide-title{
    font-size: xx-large;
}

/* Ensure the parent element has relative positioning */
.vertical-timeline-element > div {
    position: relative;
    min-height: 1px;
}

/* Style for the ID display */
.timeline-element-id {
    position: absolute;
    bottom: 5px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    background-color: rgba(0, 0, 0, 0.5); /* Optional: for better visibility */
    color: white; /* Text color */
    padding: 2px 5px; /* Padding for the ID display */
    font-size: 0.8em; /* Font size, adjust as needed */
    border-radius: 3px; /* Optional: rounding the corners */
}
