.skill-dashboard {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.skill-dashboard form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skill-dashboard h2 {
    margin-bottom: 10px;
}