.card-container {
    display: flex;
    flex-direction: column;
    overflow: visible;
    align-items: center;
    width: calc(100% / var(--projects-per-row)); /* Adjust width dynamically based on projectsPerRow */
    flex: 1;
}

.card-container .image-container {
    width: 100%;
    height: 200px; /* Set a fixed height for the image container */
    min-height: 200px; /* Ensure a minimum height */
    overflow: hidden; /* Hide any overflow */
    padding: 0; /* Remove padding for consistent sizing */
    position: relative; /* Position relative to center the image */
}

.card-container .image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the image covers the container and is centered */
    object-position: center; /* Center the image within the container */
    min-height: 40%; /* Ensure at least 40% of the image's height is shown */
}

.card-container .card-title-container {
    font-size: 38px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
}

.card-container .card-info-container {
    font-size: 24px;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
}

.GitHub-link-container .code-icon {
    padding: 10px;
    scale: 2.0;
}
