.transition_div{
    padding-top: 5px;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
}

.transition_div .login_button{
    position: absolute;
    right: 0;
    top: 0;
    height: 50px;
    width: 100px;
}
