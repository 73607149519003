.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
}

.login-page h1 {
    margin-bottom: 20px;
}

.login-page .MuiTextField-root {
    margin-bottom: 20px;
}
